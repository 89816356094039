<template>
  <div class="identity-switch px-16 py-40">
    <NavBar> </NavBar>
    <div class="text_main fw-500 font-16 mb-8">请选择您的身份</div>
    <p class="font-12 text_muted mb-62">平台支持同一用户2个身份，请先选择一个身份进行认证。</p>
    <div class="switch-card pa-24 bg-white mb-24 d-flex" @click="handleJump('DRIVER')">
      <img src="@/assets/personal-driver.png" alt="" />
      <div class="flex flex-column ml-24 justify-center align-self-center">
        <p class="text_main fw-500 font-16">个体司机</p>
        <p class="text_muted mt-8">海量货源，操作便捷</p>
      </div>
    </div>
    <div class="switch-card pa-24 bg-white mb-24 d-flex" @click="handleJump('CARRIER')">
      <img src="@/assets/team-driver.png" alt="" />
      <div class="flex flex-column ml-24 justify-center align-self-center">
        <p class="text_main fw-500 font-16">车队长</p>
        <p class="text_muted mt-8">车队管理、统一收款</p>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar/index.vue';
import { defineComponent, onMounted } from '@vue/composition-api';
import { useRouter } from '@/utils/compsitionHack';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  components: {
    NavBar
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const handleJump = item => {
      userStore.setUserIdentity(item);
      router.push(PageEnum.BASE_VERTIFIED);
    };
    onMounted(() => {});
    return {
      handleJump
    };
  }
});
</script>
<style lang="less">
.identity-switch {
  height: 100vh;
  background: #f1f3f5;
  .switch-card {
    height: 1.4rem;
    border-radius: 4px;
    box-shadow: 0px 0px 12px 0px rgba(200, 207, 216, 0.2);
  }
}
</style>
